import AppHeader from "../../../components/Header";
import React, { useRef, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppSidebar from "../../../components/NewSidebar";
import NewHeader from "../../../components/NewHeader/header";

function HomePage() {
  const appContentRef = useRef(null);
  const [roleCode, setRoleCode] = useState(null);
  const [Menus, setMenus] = useState([
    {
      title: "Dashboard",
      src: "dashboard",
      id: 0,
      path: "/dashboard",
      isSelected: false,
    },
    { title: "E-clinic", src: "eclinic", id: 1, isSelected: false },
    {
      title: "B2C",
      src: "B2C",
      id: 2,
      childrens: [
        {
          title: "Patient Registration",
          nevLink: "/patientreg",
          isSelected: false,
          subid: 0,
        },
        {
          title: "Refund",
          nevLink: "/inbox",
          isSelected: false,
          subid: 1,
        },
        {
          title: "Business Events",
          nevLink: "/businessEvent",
          isSelected: false,
          subid: 2,
        },
        {
          title: "Elite appointments",
          nevLink: "/inventoryDoctorAppointment",
          isSelected: false,
          subid: 3,
        },
        {
          title: "Booked Lab Test",
          nevLink: "/bookLabTest",
          isSelected: false,
          subid: 4,
        },
        {
          title: "Translated Prescription",
          nevLink: "/translatedPrescription",
          isSelected: false,
          subid: 5,
        },
        {
          title: "Packages",
          nevLink: "/packages",
          isSelected: false,
          subid: 6,
        },
        {
          title: "Membership",
          nevLink: "/membership",
          isSelected: false,
          subid: 7,
        },
      ],
    },
    {
      title: "Totalcare",
      src: "careplan",
      id: 3,
      childrens: [
        {
          title: "Patient Details",
          nevLink: "/bookCarePlan",
          subid: 0,
          isSelected: false,
        },
        {
          title: "Appointment booking",
          nevLink: "/careplansSchedule",
          subid: 1,
          isSelected: false,
        },
        {
          title: "Schedule",
          nevLink: "/Schedule",
          subid: 2,
          isSelected: false,
        },
        {
          title: "Careplan SOS",
          nevLink: "/careplanSos",
          subid: 3,
          isSelected: false,
        },
        {
          title: "Business Event",
          nevLink: "/careplanbusinessevent",
          subid: 4,
          isSelected: false,
        },
        {
          title: "Care Plan",
          nevLink: "/carePlan",
          subid: 5,
          isSelected: false,
        },
        {
          title: "Free Health Assessment",
          nevLink: "/preassesment",
          subid: 6,
          isSelected: false,
        },
      ],
    },
    {
      title: "Doctor",
      src: "dashboard",
      id: 4,
      path: "/doctor",
      isSelected: false,
    },
  ]);

  useEffect(() => {
    const storedRoleCode = localStorage.getItem("rolecode");
    setRoleCode(storedRoleCode);

    const storedHeaderText = localStorage.getItem("headerText");
    if (storedHeaderText) {
      const updatedMenus = Menus.map((menu) => {
        if (menu.title === storedHeaderText) {
          return { ...menu, isSelected: true };
        }
        if (menu.childrens) {
          const updatedChildrens = menu.childrens.map((submenu) => ({
            ...submenu,
            isSelected: submenu.title === storedHeaderText,
          }));
          const isSelected = updatedChildrens.some((submenu) => submenu.isSelected);
          return { ...menu, isSelected, childrens: updatedChildrens };
        }
        return menu;
      });
      setMenus(updatedMenus);
    }
  }, []);

  useEffect(() => {
    if (roleCode) {
      let initialMenus = [
        {
          title: "Dashboard",
          src: "dashboard",
          id: 0,
          path: "/dashboard",
          isSelected: false,
        },
        {
          title: "B2C",
          src: "B2C",
          id: 2,
          childrens: [
            {
              title: "Refund",
              nevLink: "/inbox",
              isSelected: false,
              subid: 0,
            },
            {
              title: "Business Events",
              nevLink: "/businessEvent",
              isSelected: false,
              subid: 1,
            },
            {
              title: "Elite appointments",
              nevLink: "/inventoryDoctorAppointment",
              isSelected: false,
              subid: 2,
            },
            {
              title: "Booked Lab Test",
              nevLink: "/bookLabTest",
              isSelected: false,
              subid: 3,
            },
            {
              title: "Translated Prescription",
              nevLink: "/translatedPrescription",
              isSelected: false,
              subid: 4,
            },
            {
              title: "Packages",
              nevLink: "/packages",
              isSelected: false,
              subid: 5,
            },
            {
              title: "Membership",
              nevLink: "/membership",
              isSelected: false,
              subid: 6,
            },
          ],
        },
        {
          title: "Totalcare",
          src: "careplan",
          id: 3,
          childrens: [
            {
              title: "Patient Details",
              nevLink: "/bookCarePlan",
              subid: 0,
              isSelected: false,
            },
            {
              title: "Appointment booking",
              nevLink: "/careplansSchedule",
              subid: 1,
              isSelected: false,
            },
            {
              title: "Schedule",
              nevLink: "/Schedule",
              subid: 2,
              isSelected: false,
            },
            {
              title: "Careplan SOS",
              nevLink: "/careplanSos",
              subid: 3,
              isSelected: false,
            },
            {
              title: "Business Event",
              nevLink: "/careplanbusinessevent",
              subid: 4,
              isSelected: false,
            },
            {
              title: "Care Plan",
              nevLink: "/carePlan",
              subid: 5,
              isSelected: false,
            },
            {
              title: "Free Health Assessment",
              nevLink: "/preassesment",
              subid: 6,
              isSelected: false,
            },
          ],
        },
        {
          title: "Doctor",
          src: "dashboard",
          id: 4,
          path: "/doctor",
          isSelected: false,
        },
      ];

      if (roleCode === "predlab@gmail.com") {
        initialMenus = [
          {
            title: "B2C",
            src: "B2C",
            id: 0,
            childrens: [
              {
                title: "Patient Registration",
                nevLink: "/patientreg",
                isSelected: false,
                subid: 0,
              },
              {
                title: "Book Consultation",
                nevLink: "/predlabconsultation",
                isSelected: false,
                subid: 1,
              },
            ],
          },
        ];
      }

      setMenus(initialMenus);
    }
  }, [roleCode]);

  const handleMenuSelect = (index) => {
    const updatedMenus = Menus.map((menu, i) => ({
      ...menu,
      isSelected: i === index,
    }));

    setMenus(updatedMenus);
    const selectedMenu = updatedMenus[index];
    localStorage.setItem("headerText", selectedMenu.title);
  };

  const handleSubmenuSelect = (parentIndex, submenuIndex) => {
    const updatedMenus = Menus.map((menu, i) => {
      if (i === parentIndex) {
        const updatedChildrens = menu.childrens.map((submenu, j) => ({
          ...submenu,
          isSelected: j === submenuIndex,
        }));
        return { ...menu, isSelected: true, childrens: updatedChildrens };
      }
      return menu;
    });

    setMenus(updatedMenus);
    const selectedSubmenu = updatedMenus[parentIndex].childrens[submenuIndex];
    localStorage.setItem("headerText", selectedSubmenu.title);
  };

  const getHeaderTitle = () => {
    const selectedMenu = Menus.find((menu) => menu.isSelected);
    if (selectedMenu && selectedMenu.childrens) {
      const selectedSubmenu = selectedMenu.childrens.find(
        (submenu) => submenu.isSelected
      );
      return selectedSubmenu ? selectedSubmenu.title : selectedMenu.title;
    }
    return selectedMenu ? selectedMenu.title : "";
  };

  const headerText = getHeaderTitle();

  return (
    <div className="flex">
      <div
        style={{
          display: "flex",
          boxShadow: "0 0 5px rgba(0,0,0,.25)",
          height: "3000px",
        }}
      >
        <AppSidebar
          Menus={Menus}
          setMenus={setMenus}
          handleMenuSelect={handleMenuSelect}
          handleSubmenuSelect={handleSubmenuSelect}
        />
        <div style={{ display: "block", width: "2000px" }}>
          <div className="headerpart">
            <NewHeader text={headerText} items={Menus} />
          </div>
          <div
            ref={appContentRef}
            id="app-content"
            style={{
              flex: 7,
              background: "#f4faff",
              borderLeft: "2px solid #f4faff",
              marginTop: "64px",
            }}
          >
            <Outlet context={{ appContentRef }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
